import store from "../store";

export default async (to, from, next) => {
  document.title = (to.meta.title || to.name) + " - Elite Real";
  if (to.name != from.name) window.scrollTo(0, 0);

  const requiresAuth = !!to.meta.requiresAuth;
  const preventAuth = !!to.meta.preventAuth;
  const authenticated = store.getters.authenticated;

  if (requiresAuth && !authenticated) return next("/auth");

  if (preventAuth && authenticated) return next("/");

  next();
};
