<template>
  <div class="auth-wrapper">
    <v-main>
      <v-container>
        <div class="d-flex justify-end justify-md-center">
          <v-img
            :src="require(`@/assets/auth/img-auth.svg`)"
            class="img-position"
          />
        </div>

        <v-row>
          <v-col cols="12" md="6">
            <router-view />
          </v-col>

          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="6">
            <v-card
              class="rounded-lg pa-16"
              height="100vh"
              color="secondary"
              flat
            >
              <v-card class="pa-10 color-card" height="100%" flat outlined>
                <div
                  class="d-flex flex-column fill-height align-center justify-center"
                >
                  <div class="text-h5 font-weight-bold background--text mb-6">
                    Faça Login para gerenciar seus investimentos
                  </div>

                  <v-carousel
                    height="45vh"
                    :show-arrows="false"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item v-for="(item, i) in carrousel" :key="i">
                      <v-row justify="center">
                        <v-col cols="12" xl="10">
                          <v-img
                            :src="item.img"
                            class="rounded-xl carousel-position"
                          />
                        </v-col>
                      </v-row>
                    </v-carousel-item>
                  </v-carousel>
                </div>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "Auth",
  data() {
    return {
      carrousel: [
        { img: require(`@/assets/auth/carrousel-1.svg`) },
        { img: require(`@/assets/auth/carrousel-2.svg`) },
      ],
    };
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
