import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import api from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage,
  modules: ["accessToken", "user"],
});

export default new Vuex.Store({
  state: {
    accessToken: "",
    user: null,
  },

  getters: {
    authenticated: ({ accessToken, user }) => !!accessToken && !!user,
    accessToken: ({ accessToken }) => accessToken,
    user: ({ user }) => user,
  },

  mutations: {
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },
  },

  actions: {
    setAccessToken({ commit }, payload) {
      commit("SET_ACCESS_TOKEN", payload);
      api.defaults.headers.common["Authorization"] = payload;
    },

    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },

    setSignIn({ dispatch }, payload) {
      dispatch("setAccessToken", payload.accessToken);
      dispatch("setUser", payload.user);
    },

    setSignOut({ dispatch }) {
      dispatch("setAccessToken", "");
      dispatch("setUser", null);
    },
  },

  modules: {},

  plugins: [vuexLocal.plugin],
});
