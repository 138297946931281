import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Main from "../pages/main/Main.vue";
import Auth from "../pages/auth/Auth.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "Main",
    meta: { requiresAuth: true },
    component: Main,
    children: [
      {
        path: "home",
        name: "Home",
        meta: { title: "Início", requiresAuth: true },
        component: () => import("@/pages/main/views/home/Home.vue"),
      },
      {
        path: "investments",
        redirect: "/investments/select-category",
        name: "Investments",
        meta: { requiresAuth: true },
        component: () =>
          import("@/pages/main/views/investments/Investments.vue"),
        children: [
          {
            path: "select-category",
            name: "SelectCategory",
            meta: {
              title: "Escolha Área Para Investir",
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/pages/main/views/investments/children/selectCategory/SelectCategory.vue"
              ),
          },
          {
            path: "select-investment/:categoryID?",
            name: "SelectInvestment",
            meta: { title: "Escolha Investimento", requiresAuth: true },
            component: () =>
              import(
                "@/pages/main/views/investments/children/selectInvestment/SelectInvestment.vue"
              ),
          },
          {
            path: "invest-now/:propertyID",
            name: "InvestNow",
            meta: { title: "Invista Agora", requiresAuth: true },
            component: () =>
              import(
                "@/pages/main/views/investments/children/investNow/InvestNow.vue"
              ),
          },
          {
            path: "investment-completed",
            name: "InvestmentCompleted",
            meta: { title: "Investimento Completo", requiresAuth: true },
            component: () =>
              import(
                "@/pages/main/views/investments/children/investmentCompleted/InvestmentCompleted.vue"
              ),
          },
        ],
      },
      {
        path: "account",
        redirect: "/account/profile",
        meta: { title: "Perfil", requiresAuth: true },
        component: () => import("@/pages/main/views/account/Account.vue"),
        children: [
          {
            path: "profile",
            name: "Profile",
            meta: {
              title: "Dados de Perfil",
              requiresAuth: true,
            },
            component: () =>
              import("@/pages/main/views/account/children/profile/Profile.vue"),
          },
          {
            path: "address",
            name: "Address",
            meta: {
              title: "Endereço",
              requiresAuth: true,
            },
            component: () =>
              import("@/pages/main/views/account/children/address/Address.vue"),
          },
          {
            path: "data-bank",
            name: "DataBank",
            meta: {
              title: "Dados bancários",
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/pages/main/views/account/children/bankData/BankData.vue"
              ),
          },
        ],
      },
      {
        path: "transactions",
        name: "Transactions",
        meta: { title: "Transações", requiresAuth: true },
        component: () =>
          import("@/pages/main/views/transactions/Transactions.vue"),
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/sign-in",
    name: "Auth",
    meta: { preventAuth: true },
    component: Auth,
    children: [
      {
        path: "sign-in",
        name: "SignIn",
        meta: { title: "Autenticação", preventAuth: true },
        component: () => import("@/pages/auth/views/signIn/SignIn.vue"),
      },
      {
        path: "sign-up",
        name: "SignUp",
        meta: { title: "Cadastro", preventAuth: true },
        component: () => import("@/pages/auth/views/signUp/SignUp.vue"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
