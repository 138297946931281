<template>
  <v-app v-if="!loading">
    <router-view />

    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { request } from "./services";
import AlertBar from "./components/alertBar/AlertBar";

export default {
  name: "App",

  components: {
    AlertBar,
  },

  data() {
    return {
      loading: true,
    };
  },

  created() {
    this.handleSession();
  },

  beforeMount() {
    this.$root.$on("get-user", this.getUser);
    this.$root.$on("sign-out", this.signOut);
    this.$root.$on("alert", this.handleAlert);
  },

  beforeDestroy() {
    this.$root.$off("get-user", this.getUser);
    this.$root.$off("sign-out", this.signOut);
    this.$root.$off("alert", this.handleAlert);
  },

  methods: {
    ...mapActions(["setUser", "setSignOut"]),

    async handleSession() {
      const token = this.$store.getters.accessToken;

      if (token) {
        try {
          const payload = {
            method: "checkUserToken",
          };

          const { data } = await request(payload);

          if (data.message === "Token OK") await this.getUser();
          else throw new Error();
        } catch {
          this.signOut();
        }
      }

      this.loading = false;
    },

    async getUser(auth = false) {
      const payload = {
        method: "getUserData",
      };

      const { data } = await request(payload);

      const user = data.userData;

      this.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        image: user.profilePhoto
          ? process.env.VUE_APP_API_URL + "/" + user.profilePhoto
          : "",
        comissioned: user.ativaComissao === "1",
        affiliateID: user.idAfiliado,
        indicatedBy: {
          id: user.quemIndicou.id,
          name: user.quemIndicou.name,
          email: user.quemIndicou.email,
          image: user.quemIndicou.profilePhoto
            ? process.env.VUE_APP_API_URL + "/" + user.quemIndicou.profilePhoto
            : "",
          affiliateID: user.quemIndicou.idAfiliado,
        },
        accountBalance: Number(user.saldo),
        commissionBalance: Number(user.saldoComissao),
        personalData: {
          fullName: user.dadosPessoais?.nome_razao || "",
          document: user.dadosPessoais?.cpfCnpj || "",
          birthDate: user.dadosPessoais?.dataNascimento || "",
          phone: user.dadosPessoais?.telefone || "",
          profession: user.dadosPessoais?.profissao || "",
          frontDocument: user.dadosPessoais?.docfrente
            ? process.env.VUE_APP_API_URL + "/" + user.dadosPessoais?.docfrente
            : "",
          backDocument: user.dadosPessoais?.docverso
            ? process.env.VUE_APP_API_URL + "/" + user.dadosPessoais?.docverso
            : "",
          rejectMessage: user.dadosPessoais?.obs || "",
          status: user.dadosPessoais?.status || "0",
        },
        address: {
          zipCode: user.dadosResidencial?.cep,
          number: user.dadosResidencial?.numero,
          neighborhood: user.dadosResidencial?.bairro,
          state: user.dadosResidencial?.estado,
          street: user.dadosResidencial?.rua,
          complement: user.dadosResidencial?.complemento,
          city: user.dadosResidencial?.cidade,
          country: user.dadosResidencial?.pais,
          addressProof: user.dadosResidencial?.comprovante
            ? process.env.VUE_APP_API_URL +
              "/" +
              user.dadosResidencial?.comprovante
            : "",
          rejectMessage: user.dadosResidencial?.obs || "",
          status: user.dadosResidencial?.status || "",
        },
        bankKeys: (user.chavesBancarias || []).map((el) => ({
          id: el.id,
          keyType: el.tipoChave,
          pixKey: el.chave,
        })),
      });

      if (auth)
        this.$router.push({ name: "Home" }).catch(() => {
          /* ignore */
        });
    },

    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },

    async signOut() {
      this.loading = true;

      await this.setSignOut();
      await this.$router.push({ name: "SignIn" }).catch(() => {
        /* ignore */
      });

      this.loading = false;
    },
  },
};
</script>
