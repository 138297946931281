<template>
  <div class="main-wrapper">
    <v-app-bar class="background" flat app>
      <div class="d-flex fill-width py-2">
        <v-app-bar-nav-icon @click="drawer = !drawer" />

        <v-spacer />

        <v-card class="rounded-xxl">
          <div class="d-flex align-center px-4 py-2">
            <v-icon left>mdi-bell-outline</v-icon>

            <v-icon left>mdi-heart-outline</v-icon>

            <v-avatar class="mr-2" size="2.5rem" color="background2">
              <v-img v-if="user.image" :src="user.image" />

              <v-icon v-else>mdi-account-outline</v-icon>
            </v-avatar>

            <div class="mr-4">{{ user.name }}</div>

            <v-icon @click="signOut()">mdi-chevron-down</v-icon>
          </div>
        </v-card>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      class="drawer-gradient"
      floating
      dark
      app
    >
      <div class="d-flex flex-column fill-width">
        <div class="d-flex justify-center my-10">
          <v-img
            :src="require('@/assets/elitereal/logotype.svg')"
            contain
            max-width="8rem"
          />
        </div>

        <v-list class="py-0" color="transparent" width="100%" height="100%" nav>
          <router-link
            v-for="(item, i) in routes"
            v-slot="{ href, navigate, isActive }"
            :to="item.path"
            :key="i"
            custom
          >
            <v-list-item
              class="rounded-lg"
              :href="href"
              :class="{
                'secondary--text': isActive,
                disabled: item.disabled || item.isComing,
              }"
              @click="navigate"
            >
              <v-list-item-icon class="mr-3">
                <v-icon :color="isActive ? 'secondary' : 'white'">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title class="d-flex">
                <span
                  :class="
                    item.disabled || item.isComing
                      ? 'secondaryText--text'
                      : 'primaryText--text'
                  "
                >
                  {{ item.label }}
                </span>
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Main",

  data() {
    return {
      drawer: true,
      routes: [
        {
          icon: "mdi-home",
          label: "Dashboard",
          path: "/home",
        },
        {
          icon: "mdi-cart",
          label: "Investimentos",
          path: "/investments",
        },
        {
          icon: "mdi-poll",
          label: "Transações",
          path: "/transactions",
        },
        {
          icon: "mdi-view-dashboard",
          label: "Comissões",
          path: "/commissions",
        },
        {
          icon: "mdi-account",
          label: "Configuração da conta",
          path: "/account",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    signOut() {
      this.$root.$emit("sign-out");
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
